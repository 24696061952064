<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter"
      style="height: 90vh">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-villages :dataTable="villages" @editForm="editForm" @createVillages="createVillages"></table-villages>
    </div>
    <edit-villages :id="id" @refresh="refresh" />
    <createVillage @refresh="refresh" />
  </div>
</template>

<script>
import tableVillages from "@/views/component/Table/tableVillages.vue";
import editVillages from "@/views/component/Modal/ModalVillages/editVillages.vue";
import createVillage from "@/views/component/Modal/ModalVillages/createVillage.vue";
export default {
  components: {
    tableVillages,
    editVillages,
    createVillage,
  },
  data() {
    return {
      id: null,
      villages: [],
      showPreloader: false,
    };
  },
  methods: {
    createVillages() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    editForm(id) {
      this.id = id;
      setTimeout(() => {
        this.$bvModal.show("editVillages");
      }, 0);
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get("locations").then((res) => {
        this.villages = res.data.data;
        this.$store.commit("pageData/setdataCount", this.villages.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.refresh();
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.refresh();
      }
    },
  },
};
</script>

<style></style>
