<template>
  <b-modal
    id="editVillages"
    title="Изменить"
    size="sm"
    hide-footer
    :no-close-on-backdrop="true"
    @shown="getVillage"
    @hidden="clearModal, $bvModal.hide('editVillages')"
    @close="clearModal, $bvModal.hide('editVillages')">
    <div>
      <b-row>
        <b-col cols="12">
          <label>Регионы <i class="required">*</i></label>
          <v-select
            v-model="village.regions"
            label="name"
            placeholder="Регионы"
            :options="regions"
            @input="setCities"
            :reduce="(regions) => regions" />
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Города <i class="required">*</i></label>
            <v-select
              v-model="village.cities"
              label="name"
              placeholder="Города"
              :options="cities"
              :disabled="disabCities"
              @input="setDistricts"
              :reduce="(cities) => cities" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Населенные пункты <i class="required">*</i></label>
            <v-select
              v-model="village.districts"
              label="name"
              placeholder="Районы"
              :disabled="disabDistricts"
              @input="setName"
              :options="districts"
              :reduce="(districts) => districts">
              <template slot="option" slot-scope="option">
                <div>
                  {{ option.name }}
                  ({{ option.typeShortName }})
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (тч) <i class="required">*</i></label>
            <b-form-input disabled type="text" v-model="villageName.name_tj" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (ru) <i class="required">*</i></label>
            <b-form-input type="text" v-model="villageName.name_ru" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (en) <i class="required">*</i></label>
            <b-form-input type="text" v-model="villageName.name_en" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <div class="d-flex">
              <div>
                <label>Широта: <i class="required">*</i></label>
                <b-form-input v-model="center.lat" type="number" id="latitudeInput" />
              </div>
              <div class="ml-2">
                <label>Долгота: <i class="required">*</i></label>
                <b-form-input v-model="center.lng" type="number" id="longitudeInput" />
              </div>
              <div class="mt-2 ml-2">
                <b-button variant="primary" @click="openMap">
                  <feather-icon icon="MapPinIcon" size="16" />
                </b-button>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col style="margin-top: 15px; margin-left: 15px" class="custom-control custom-checkbox">
          <input type="checkbox" v-model="redraw" class="custom-control-input" id="customCheck1" />
          <label class="custom-control-label" for="customCheck1">{{ redraw ? "не изменить" : "заново" }}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button :disabled="submitButtonDisabled" class="mt-2 col-md-5" variant="primary" @click="editVillage">
            Изменить
          </b-button>
          <b-button class="mt-2 col-md-5" variant="secondary" @click="clearModal, $bvModal.hide('editVillages')">
            Отмена
          </b-button>
        </b-col>
      </b-row>
      <mapVillage @latLng="latLng" :address="center" />
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import mapVillage from "@/views/component/Map/map.vue";
export default {
  components: {
    mapVillage,
    vSelect,
  },
  props: ["id"],
  data() {
    return {
      regions: [],
      cities: [],
      districts: [],
      village: {
        regions: null,
        cities: null,
        districts: null,
      },
      disabCities: true,
      disabDistricts: true,
      center: {
        id: 1,
        lat: null,
        lng: null,
        zoom: 14,
      },
      villageName: {
        name_ru: null,
        name_tj: null,
        name_en: null,
      },
      redraw: true,
      submitButtonDisabled: false,
      dataVilage: null,
    };
  },
  methods: {
    async getVillage() {
      this.clearModal();
      try {
        await this.getRegions();
        let res = await this.$http.get(`locations/${this.id}`);
        let village = res.data.data;
        this.regions.forEach((region) => {
          if (region.name == village.subregion) {
            this.village.regions = region;
          }
        });
        await this.setCities(this.village.regions);
        this.cities.forEach((region) => {
          if (region.name == village.region) {
            this.village.cities = region;
          }
        });
        await this.setDistricts(this.village.cities);
        this.districts.forEach((district) => {
          if (district.name == village.name.tj) {
            this.village.districts = district;
            this.setName(district);
          }
        });
        this.villageName.name_ru = village.name.ru;
        this.villageName.name_en = village.name.en;
        this.center.lat = village.lat;
        this.center.lng = village.lng;
        this.dataVilage = village;
      } catch (error) {
        console.log(error);
      }
    },
    async getRegions() {
      await this.$http.get("locations/regions").then((res) => {
        this.regions = res.data.data;
      });
    },
    async setCities($event) {
      if ($event !== null) {
        await this.$http.get(`locations/${$event.code}/cities`).then((res) => {
          this.cities = res.data.data;
          this.disabCities = false;
        });
      } else {
        this.village.cities = null;
        this.village.districts = null;
        this.cities = [];
        this.districts = [];
        this.disabCities = true;
        this.disabDistricts = true;
      }
    },
    latLng(data) {
      this.center.lat = data.lat;
      this.center.lng = data.lng;
    },
    async setDistricts($event) {
      if ($event !== null) {
        await this.$http.get(`locations/${$event.code}/villages`).then((res) => {
          this.districts = res.data.data;
          this.disabDistricts = false;
          this.villageName.name_tj = $event.name;
        });
      } else {
        this.villageName.name_tj = null;
        this.districts = [];
        this.disabDistricts = true;
      }
    },
    setName($event) {
      if ($event !== null) {
        this.villageName.name_tj = $event.name;
      } else {
        this.villageName.name_tj = this.village.cities.name;
      }
    },
    openMap() {
      this.$bvModal.show("mapVue");
    },
    editVillage() {
      this.submitButtonDisabled = false;
      let vill = {
        ...this.villageName,
        id: this.id,
        lat: this.center.lat,
        lng: this.center.lng,
        code: this.village.districts.code,
        type: this.village.districts.typeShortName,
        region: this.village.cities.name,
        sub_region: this.village.regions.name,
        redraw: (this.redraw = this.redraw ? 1 : 0),
      };

      this.$http
        .put(`locations/${this.id}`, vill)
        .then((res) => {
          console.log(res);

          this.$emit("refresh");
          this.clearModal();
          this.$bvModal.hide("editVillages");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.data.message,
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: "Произошла ошибка",
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    clearModal() {
      this.regions = [];
      this.cities = [];
      this.districts = [];
      this.village = {
        regions: null,
        cities: null,
        districts: null,
      };
      this.disabCities = true;
      this.disabDistricts = true;
      this.center = {
        id: 1,
        lat: null,
        lng: null,
        zoom: 14,
      };
      this.villageName = {
        name_ru: null,
        name_tj: null,
        name_en: null,
      };
      this.redraw = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  color: red;
}
</style>
