<template>
  <div>
    <b-modal
      :id="$route.name + 'CreateModal'"
      title="Создать"
      size="sm"
      :no-close-on-backdrop="true"
      hide-footer
      @close="closeModal"
      no-enforce-focus
      @show="opened">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label>Регионы <i class="required">*</i></label>
            <v-select
              v-model="village.regions"
              label="name"
              placeholder="Регионы"
              :options="regions"
              @input="setCities"
              :reduce="(regions) => regions" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Города <i class="required">*</i></label>
            <v-select
              v-model="village.cities"
              label="name"
              placeholder="Города"
              :options="cities"
              :disabled="disabCities"
              @input="setDistricts"
              :reduce="(cities) => cities" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Населенные пункты <i class="required">*</i></label>
            <v-select
              v-model="village.districts"
              label="name"
              placeholder="Районы"
              :disabled="disabDistricts"
              @input="setName"
              :options="districts"
              :reduce="(districts) => districts">
              <template slot="option" slot-scope="option">
                <div>
                  {{ option.name }}
                  ({{ option.typeShortName }})
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (тч) <i class="required">*</i></label>
            <b-form-input disabled type="text" v-model="villageName.name_tj" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (ru) <i class="required">*</i></label>
            <b-form-input type="text" v-model="villageName.name_ru" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Имя (en) <i class="required">*</i></label>
            <b-form-input type="text" v-model="villageName.name_en" id="basicInput" placeholder="Имя" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <div class="d-flex">
              <div>
                <label>Широта: <i class="required">*</i></label>
                <b-form-input v-model="center.lat" type="number" id="latitudeInput" />
              </div>
              <div class="ml-2">
                <label>Долгота: <i class="required">*</i></label>
                <b-form-input v-model="center.lng" type="number" id="longitudeInput" />
              </div>
              <div class="mt-2 ml-2">
                <b-button variant="primary" @click="openMap">
                  <feather-icon icon="MapPinIcon" size="16" />
                </b-button>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button :disabled="submitButtonDisabled" class="mt-2 col-md-5" variant="primary" @click="createVillage">
            Добавить
          </b-button>
          <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal"> Отмена </b-button>
        </b-col>
      </b-row>
      <mapVillage @latLng="latLng" />
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import mapVillage from "@/views/component/Map/map.vue";
export default {
  components: {
    mapVillage,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      submitButtonDisabled: false,
      regions: [],
      cities: [],
      districts: [],
      village: {
        regions: null,
        cities: null,
        districts: null,
      },
      disabCities: true,
      disabDistricts: true,
      center: {
        lat: null,
        lng: null,
      },
      villageName: {
        name_ru: null,
        name_tj: null,
        name_en: null,
      },
    };
  },
  methods: {
    opened() {
      this.$http.get("locations/regions").then((res) => {
        this.regions = res.data.data;
      });
    },
    closeModal() {
      (this.submitButtonDisabled = false),
        (this.regions = []),
        (this.cities = []),
        (this.districts = []),
        (this.village = {
          regions: null,
          cities: null,
          districts: null,
        }),
        (this.disabCities = true),
        (this.disabDistricts = true),
        (this.center = {
          lat: null,
          lng: null,
        }),
        (this.villageName = {
          name_ru: null,
          name_tj: null,
          name_en: null,
        });
      this.$bvModal.hide(this.$route.name + "CreateModal");
    },
    createVillage() {
      this.submitButtonDisabled = true;
      let vill = {
        code: null,
        type: null,
        region: null,
        sub_region: null,
      };
      if (this.village.districts !== null) {
        vill.code = this.village.districts.code;
        vill.type = this.village.districts.typeShortName;
        vill.region = this.village.cities.name;
        vill.sub_region = this.village.regions.name;
      } else if (this.village.cities !== null) {
        vill.code = this.village.cities.code;
        vill.type = this.village.cities.typeShortName;
        vill.region = this.village.regions.name;
        vill.sub_region = this.village.regions.name;
      }
      let data = {
        ...vill,
        ...this.villageName,
        ...this.center,
      };
      this.$http
        .post("locations/add/rectangle", data)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
          this.closeModal();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    setCities($event) {
      if ($event !== null) {
        this.$http.get(`locations/${$event.code}/cities`).then((res) => {
          this.cities = res.data.data;
          this.disabCities = false;
        });
      } else {
        this.village.cities = null;
        this.village.districts = null;
        this.cities = [];
        this.districts = [];
        this.disabCities = true;
        this.disabDistricts = true;
      }
    },
    latLng(data) {
      this.center.lat = data.lat;
      this.center.lng = data.lng;
    },
    setDistricts($event) {
      if ($event !== null) {
        this.$http.get(`locations/${$event.code}/villages`).then((res) => {
          this.districts = res.data.data;
          this.disabDistricts = false;
          this.villageName.name_tj = $event.name;
        });
      } else {
        this.villageName.name_tj = null;
        this.districts = [];
        this.disabDistricts = true;
      }
    },
    setName($event) {
      if ($event !== null) {
        this.villageName.name_tj = $event.name;
      } else {
        this.villageName.name_tj = this.village.cities.name;
      }
    },
    openMap() {
      this.$bvModal.show("mapVue");
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  color: red;
}
</style>
